<template>
<v-container class="pa-0">
  <v-row class="mx-auto text-white" style="max-width:1098">
    <v-col cols="4" md="" @click="add_items('B.F. 대검')" class=" pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*2" :src="item_img_list['B.F. 대검_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">대검</div>
      </div>
    </v-col>
    <v-col cols="4" md="" @click="add_items('곡궁')" class="pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*2" :src="item_img_list['곡궁_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">곡궁</div>
      </div>
    </v-col>
    <v-col cols="4" md="" @click="add_items('쇠사슬 조끼')" class="pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*2" :src="item_img_list['쇠사슬 조끼_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">조끼</div>
      </div>
    </v-col>
    <v-col cols="4" md="" @click="add_items('음전자 망토')" class="pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*2" :src="item_img_list['음전자 망토_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">망토</div>
      </div>
    </v-col>
    <v-col cols="4" md="" @click="add_items('쓸데없이 큰 지팡이')" class="pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*2" :src="item_img_list['쓸데없이 큰 지팡이_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">지팡이</div>
      </div>
    </v-col>
    <v-col cols="4" md="" @click="add_items('여신의 눈물')" class="pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*2" :src="item_img_list['여신의 눈물_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">여눈</div>
      </div>
    </v-col>
    <v-col cols="4" md="" @click="add_items('거인의 허리띠')" class="pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*2" :src="item_img_list['거인의 허리띠_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">벨트</div>
      </div>
    </v-col>
    <v-col cols="4" md="" @click="add_items('연습용 장갑')" class="pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*2" :src="item_img_list['연습용 장갑_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">장갑</div>
      </div>
    </v-col>
    <v-col cols="4" md="" @click="add_items('뒤집개')" class="pointer">
      <div class="basic-item-button">
          <v-img :width="item_width*1.9" :src="item_img_list['뒤집개_btn']"></v-img>
          <div class="text-subtitle basic-item-name position-absolute d-flex justify-center align-center">뒤집개</div>
      </div>
    </v-col>
    <v-col cols="12">
        <div class="d-flex px-md-6 overflow-x-auto">
            <img :src="item_img_list['refresh']" :style="{'width' : item_width+'px', 'height' : item_width+'px'}" class="bg-white rounded-circle mr-3 pointer overflow-y-hidden" @click="delete_all">
          <div v-for="(item, index) in selected_items" :key="index" class="mr-3 position-relative pointer">
            <img @click="to_combine_list(item, index)" :width="item_width" :src="item_img_list[item]" class="rounded-circle selected-item" :class="{'border-red' : combine_index_list.includes(index)}" style="min-height : 46px;"/>
            <span color="primary" @click="delete_item(index)" class="py-n1 position-absolute delete-item d-flex align-center justify-center" style="top:0; right:0">x</span>
          </div>

        </div>

        <div class="text-body-2 mt-4">
          {{checkItem}}
        </div>
        <div class="mt-3">
          <v-btn v-show="checkCombine" @click="combine" color="primary">조합하기</v-btn>
        </div>
    </v-col>
  </v-row>
  <v-container v-if="last_item.length" class="position-fixed" style="bottom : 2rem; width:100%; max-width : 1098px;" v-model='is_loading'>
    <div class="img-error mx-auto text-white text-subtitle text-center py-2 position-relative" id="cookie-controller" @click='get_last_item'>
      <span color="primary" class="py-n1 position-absolute delete-item d-flex align-center justify-center text-white " style="top:0; right:-1.5rem; width:1.5rem; height:1.5rem;" @click="cookie_close">x</span>
        마지막으로 선택한 아이템을 불러옵니다.
    </div>
  </v-container>
</v-container>
</template>

<script>
import { API } from 'aws-amplify'; //중복 import 유의하기
import VueCookies  from 'vue-cookies';

export default {
  name: 'ItemButton',
  props:[
    'item_img_list',
    'item_list'
  ],
  data: () => ({
    selected_items : [],
    combine_list : [],
    combine_index_list : [],
    completed_items : [],
    basic_item_list : ['B.F. 대검','곡궁', '쇠사슬 조끼', '음전자 망토', '쓸데없이 큰 지팡이', '여신의 눈물','거인의 허리띠','연습용 장갑', '뒤집개'],
    item_width : 48,
    last_item : [],
  }),
  methods : {
    recommend : function(){
      if (this.getItemAmt() <2 ){
        return;
      }
      this.$emit('getRecommend', this.selected_items)    
    },

    add_items : function(item){
      this.last_item = [];
      if (this.selected_items.length > 7){
        alert('8개 까지만 가능합니다')
        return;
      }
      this.selected_items.push(item);
      VueCookies.set('lastItem', this.selected_items, {expires : '2h'});
      // console.log(VueCookies.get('lastItem'))
      this.recommend();
    },

    add_items_with_last_item : function(item){
      if (this.selected_items.length > 7){
        alert('8개 까지만 가능합니다')
        return;
      }
      this.selected_items.push(item);
      VueCookies.set('lastItem', this.selected_items, {expires : '2h'});
      // console.log(VueCookies.get('lastItem'))
      this.recommend();
    },


    delete_item : function(index){
      if (this.combine_index_list.includes(index)) {
        let c_index = this.combine_index_list.indexOf(index);
        this.combine_index_list.splice(c_index, 1);
        this.combine_list.splice(c_index, 1);
      }

      this.selected_items.splice(index, 1);

      this.recommend();
    },

    delete_all : function(){
      this.combine_index_list = [];
      this.combine_list = [];
      this.selected_items =[];

      this.recommend();
      VueCookies.remove('lastItem');
    },

    to_combine_list : function(item, index){
      if(!this.basic_item_list.includes(item)){
        return
      }

      if (this.combine_index_list.includes(index)) {
        let c_index = this.combine_index_list.indexOf(index);
        this.combine_index_list.splice(c_index, 1);
        this.combine_list.splice(c_index, 1);
        return;
      } 

      if (this.combine_list.length == 2) {
        this.combine_list.shift();
        this.combine_index_list.shift();
      }

      this.combine_list.push(item);
      this.combine_index_list.push(index);

    },

    combine_ : async function(){
      API.get('recommend', '/combine', {
        'queryStringParameters' : {'items' : this.combine_list }
      })
      .then(response => {
        this.selected_items.push(response);
        let c_list_sorted = this.combine_index_list.sort()
        let index_1 = c_list_sorted[0]
        let index_2 = c_list_sorted[1]


        this.combine_list = [];
        this.combine_index_list = []; 
        this.selected_items.splice(index_2,1);
        this.selected_items.splice(index_1,1);

        this.recommend();
      })
      .catch(error => {
        console.log(error);
      })
    },
    //combine 함수 바꾸기!
    combine : function(){
      let combine_list = this.combine_list;
      combine_list.sort();
      let item_list = Object.values(this.item_list);
      let item_list_length = item_list.length;

      for (let i =0; i <= item_list_length; i++) {
        if (item_list[i][0] == combine_list[0] && item_list[i][1] == combine_list[1]){
          let item = Object.keys(this.item_list)[i]
          this.selected_items.push(item);
          let c_list_sorted = this.combine_index_list.sort()
          let index_1 = c_list_sorted[0]
          let index_2 = c_list_sorted[1]

          this.combine_list = [];
          this.combine_index_list = []; 
          this.selected_items.splice(index_2,1);
          this.selected_items.splice(index_1,1);

          this.recommend();

          break
        }
      }



    },
    getItemAmt : function(){
      return this.selected_items.length + this.completed_items.length
    },

    removeCookie : function(){
      VueCookies.remove('lastItem');
    },

    async cookie_close(){
      VueCookies.remove('lastItem');
      console.log(VueCookies.get('lastItem'));
      this.last_item = [];
    },
    async get_last_item(){
      let add_items = this.add_items_with_last_item;
      this.last_item.forEach(e => add_items(e))
      this.last_item = [];
    }

  },
  computed : {
    checkItem(){
      return this.selected_items.length + this.completed_items.length < 2 ? "획득한 아이템을 선택해주세요 (2개 이상)" : ""
    },
    checkCombine(){
      return this.combine_list.length == 2 ? true : false
    }
  },
  async mounted(){
    // 쿠키 정보를 받아옵니다.
    let last_item = VueCookies.get('lastItem')
    if (last_item) {
      last_item = last_item.split(',')
      this.last_item = last_item;
    }
    
  //   window.addEventListener('beforeunload', this.removeCookie);
  //   //쿠키에 저장된 정보를 가져옵니다.
  //   let lastItem = VueCookies.get('lastItem');
  //   this.selected_items = lastItem.split(',');
  //   this.recommend();
  }
}
</script>

<style scoped>

.basic-item-button{
  border : solid 1px #363636;
  min-height : 48px;
  position : relative;
}
.basic-item-name {
  width:100%;
  height:100%;
  top:0;
}


.selected-item {
  border : 1px solid #363636;
}

.border-red {
  border: 1px solid rgba(255, 0, 0, 0.767);
}

</style>
