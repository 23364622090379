/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "recommend",
            "endpoint": "https://7i2pc3mf67.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-2:20111ea1-0970-4382-b609-050538484137",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_ecEKifrYC",
    "aws_user_pools_web_client_id": "25glfonv539uilaqhj7qgj8cp5",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lolchessgoimage210939-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
