<template>
  <v-app>
    <v-app-bar color="#121212" class="px-md-10 text-white">
      <v-app-bar-title text='lolchego' class="font-weight-black pointer" @click="refresh"></v-app-bar-title>
      <div class="ml-auto font-weight-medium pointer" @click="toGuide">롤체고 사용 방법</div>
    </v-app-bar>
    <v-main>
      <div class="main-wrapper mx-auto">
        <transition name="fade">
          <div v-if="is_loading" class="text-white text-center text-h6">
            필요한 데이터를 불러오는 중입니다...
            <v-progress-linear
              buffer-value="0"
              stream
            ></v-progress-linear>
          </div>
        </transition>
        <ItemButton @getRecommend="getRecommend" :item_img_list="item_img_list" :item_list="item_list" class="mt-12"/>

        <div class="text-white text-center mt-4 text-h6" > 추천 덱 </div>
        <DeckList class="mt-8" :deck="deck" :status="status" :item_img_list="item_img_list" :item_list="item_list" :champion_list="champion_list" :selected_items="selected_items" @imgReplace="imgReplace"  :meta_desc="meta_desc"/>
        <v-container v-if="img_error" class="position-fixed" style="bottom : 2rem; width:100%; max-width : 1098px;">
          <div class="img-error mx-auto text-white text-subtitle text-center py-2" @click="refresh">
          이미지가 만료되었습니다.<br/>새로고침 후 사용해주세요!
          </div>
        </v-container>

      </div>

    </v-main>
  </v-app>
</template>

<script>
import ItemButton from './components/ItemButton.vue'
import DeckList from './components/DeckList.vue'
import { API, Storage } from 'aws-amplify';
import {debounce} from 'vue-debounce'

export default {
  name: 'App',

  components: {
    ItemButton,
    DeckList
  },

  data: () => ({
      items : [],
      deck : "",
      status : 0, //0은 대기 및 완료, 1은 로딩중, 2는 오류
      item_img_list : {},
      item_list : {},
      champion_list : {},
      selected_items : [],
      is_loading : true,
      meta_desc : {},
      img_error : false,
  }),
  methods : {
    getRecommend : debounce(async function(items){
      this.selected_items = items;
      this.status = 1;
      this.deck = "";

      API.get('recommend', '/recommend', {
        'queryStringParameters' : {'items' : items }
      })
      .then(response => {
        this.deck = response
        this.status = 0
      })
      .catch(error => {
        console.log(error);
        this.status = 2
      })
    }, 700),
    refresh : function(){
      window.location.reload();
    },
    async select(items){
      this.selected_items = items;
    },
    toGuide : function(){
      window.open('https://peppered-attempt-cfd.notion.site/f47d6ffd6b714315ac0f0c2fca22511b', '_blank');
    }
    ,
    
    async getItemInfos(){
      API.get('recommend', '/items',{
        'queryStringParameters' : {}
      })
      .then(response => {
        this.item_list = response;
      })
      .catch(error => {
        console.log(error);
      })
    },

    async getMetaDesc(){
      API.get('recommend', '/description',{
        'queryStringParameters' : {}
      })
      .then(response => {
        this.meta_desc = response;
      })
      .catch(error => {
        console.log(error);
      })
    },


    async getChampions(){
      API.get('recommend', '/champions',)
      .then(response => {
        this.champion_list = response;
        let champion_list = this.champion_list
        let keys = Object.keys(champion_list)

        keys.forEach(async function(value, index){
          let url = await Storage.get('champions/'+value+'.png');
          champion_list[value]['image'] = url
          index
        })

        setTimeout(this.is_loading=false, 700);
      })
      .catch(error => {
        console.log(error);
      })
    },

    async imgReplace(type){
      // 1은 챔피언 2는 아이템
      console.log('imgReplace');
      this.img_error = true;
      if (type == 1){
      //   this.getChampions();
      // } else {
      //   this.getItemInfos();
      }
    },

  },
  async mounted(){
    // 아이템 이미지를 받아옵니다.
    let dict = this.item_img_list
    let item_list = await Storage.list('items/')
    item_list.forEach(async function(value, index){
      let url = await Storage.get(value.key);
      value = value.key.slice(6,-4);
      dict[value] = url;
      index
    })

    // 아이템 정보를 받아옵니다.
    this.getItemInfos();
    // 챔피언 정보와 이미지를 받아옵니다
    this.getChampions();
    // 덱 정보를 받아옵니다.
    this.getMetaDesc();

  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');


#app{
  font-family : 'Noto Sans KR', sans-serif !important;
  color : white;
}
.v-main{
  background-color : #121212;
}

.main-wrapper {
  max-width : 1098px;
  min-height : 100vh;
}

.pointer {
  cursor : pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.delete-item {
  width: 1rem;
  height : 1rem;
  border-radius: 50%;
  background-color : #363636;
  padding-top : -1px;
  font-size : 0.9rem;
}

.img-error{
  background-color : #2f6fdb;
  width : 80%;
  border-radius : 3rem;
  max-width : 700px;
  cursor : pointer;
}

</style>
