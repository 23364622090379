<template>
<v-container class="pa-0">
  <div v-if="deck">
    <v-text-field label="보유하고 있는 챔피언으로 덱을 찾아보세요" class="text-white" v-model="inputChampions" 
      @change="filterDeck" @click:clear="filterDeck" @input="inputKeyUp" :hint="searchHelp" @blur="filterDeck" clearable></v-text-field>
  </div>
  <v-expansion-panels color="#121212" class="text-white" multiple>
    <v-expansion-panel v-if="!deck && status==0" disabled>
        <v-expansion-panel-title class="text-left text-white">
          <v-row>
            <v-col cols="12">
              아이템을 2개 이상 선택해주세요
            </v-col>
          </v-row>
        </v-expansion-panel-title>
    </v-expansion-panel>

    <v-expansion-panel v-show="status==1" disabled>
        <v-expansion-panel-title class="text-left text-white">
          <v-row>
            <v-col cols="12">
              로딩 중입니다
              <v-progress-circular indeterminate color="white" class="ml-4"></v-progress-circular>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
    </v-expansion-panel>

    <v-expansion-panel v-if="status==2">
        <v-expansion-panel-title class="text-left">
          <v-row>
            <v-col cols="12">
              오류가 발생하였습니다, 다시 시도해주세요
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </v-expansion-panel-text>
    </v-expansion-panel>


    <v-expansion-panel v-else
      v-for="(row, i) in deck"
      :key="i" class="mb-0" @click="onExpansionPanelClick" :class="{'d-none' : !deckFiltered.includes(row) && searchChampion.length > 0 && deckFiltered.length != 0}"
    >

    <!-- 여기 이하로 모두 동일합니다. -->
    <v-btn @click="pick(row)" icon="mdi-pin" color="#121212" size="x-small" border="false" class="d-block d-md-none" style="background-color:default; position:absolute;top:8px;right:8px;z-index:3; ">
    </v-btn>
     <v-btn @click="pick(row)" icon="mdi-pin" color="#121212" size="x-small" border="false" class="d-none d-md-block" style="background-color:default; position:absolute;top:2rem;left:22%;z-index:3; ">
    </v-btn>   
    
      <v-expansion-panel-title class="text-left basic-border">
        <v-row>
          <v-col cols="12" md="3" class="d-flex align-center">
            {{parseInt(i)+1}}. {{row.name}}   
          </v-col>
          <v-col cols="12" md="9" class="">
              <v-row>
                <v-col cols="3" md="" v-for="(dic, i) in row.champions" :key="i" class="text-center">
                  <img @error="imgReplace(1)" :src="champion_list[dic.name]['image']" style="width : 48px; height : 48px;" class="rounded-circle" :class="'champion-cost-'+champion_list[dic.name]['cost']"/>
                  <div class="text-caption text-center mx-auto" style="width : max-content;" :class="{'core-champion' : dic.items, 'search-champion font-weight-bold' : searchChampion.includes(dic.name) }">{{dic.name}} </div>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="mt-2">
          <v-col cols="12" class="font-weight-thin">
            {{row.name}}덱은 {{meta_desc[row.name]}}

            <!-- 설명이 여기 들어갑니다. -->
            <!-- 딜러 3신기 중 {{row.items_basic_dealer_cnt}}개를 바로 만들 수 있어요 <br/>
            items_sub_dealer_cnt : {{row.items_sub_basic_dealer_cnt}} <br/>
            {{row.items_basic_total_cnt}}개의 완성 템을 지금 바로 사용할 수 있어요<br/>
            items_sub_basic_total_cnt : {{row.items_sub_basic_total_cnt}} <br/>
            3신기를 만들기 위한 {{row.items_basic_sum_dealer_cnt}}개의 아이템을 보유하고 있어요 <br/>
            조합 아이템 총 {{row.items_basic_sum_total_cnt}}개가 겹쳐요 <br/>
            deck_total_count : {{row.deck_total_count}} -->
          </v-col>

          <!-- 딜러 아이템 총합 -->
          <v-col cols="12" md="1" class="d-flex align-center pr-0">
            <span class="font-weight-light">아이템</span>
          </v-col>
          <v-col cols="12"  md="5" class="">
            <div v-for="(dic, i) in row.champions" :key="i" class="d-flex mr-7 text-center">
              <v-row v-if="dic.items" class="d-flex mb-2">
                <v-col cols="3" md="2" class="pr-0">
                  <img @error="imgReplace(1)" :src="champion_list[dic.name]['image']" style="width : 2.5rem;" class="rounded-circle" :class="'champion-cost-'+champion_list[dic.name]['cost']"/>
                  <div class="text-caption text-center">{{dic.name}}</div>
                  <div v-if="dic.type == 'D'" class="text-caption text-center text-red-lighten-2 ">핵심 딜러</div>
                  <div v-else-if="dic.type == 'SD'" class="text-caption text-center text-orange-lighten-1">서브 딜러</div>
                  <div v-else-if="dic.type == 'U'" class="text-caption text-center text-green-lighten-1">유틸</div>
                  <div v-else-if="dic.type == 'T'" class="text-caption text-center text-blue-lighten-1">탱커</div>
                </v-col>
                <v-col cols="9" md="10" class="d-flex overflow-x-auto">
                  <div v-for="(item, i) in dic.items" :key="item" class="text-center mr-md-2" :class="{'mr-2' : i == dic.items.length -1}">
                    <img @error="imgReplace(2)" :src="item_img_list[item]" class="rounded-circle d-block mb-1 mx-1" style="width : 2.5rem;" :class="{'item-have-2' : selected_items.includes(item)}"/>
                    <div v-if="toBasicItem(item)[0] == toBasicItem(item)[1]" class="d-flex justify-center">
                      <div class="text-white d-inline mr-1 mr-md-2">
                        <img @error="imgReplace(2)" :src="item_img_list[toBasicItem(item)[0]]" style="width : 20px;" :class="{'item-have' : selected_items.includes(toBasicItem(item)[0])}"/>
                      </div>
                      <div class="text-white d-inline">
                        <img @error="imgReplace(2)" :src="item_img_list[toBasicItem(item)[1]]" style="width : 20px;" :class="{'item-have' : selected_items.filter(x => x==toBasicItem(item)[0]).length >= 2}"/>
                      </div>
                    </div>
                    <div v-else>
                      <div class="text-white d-inline mr-1 mr-md-2">
                        <img @error="imgReplace(2)" :src="item_img_list[toBasicItem(item)[0]]" style="width : 20px;" :class="{'item-have' : selected_items.includes(toBasicItem(item)[0])}"/>
                      </div>
                      <div class="text-white d-inline">
                        <img @error="imgReplace(2)" :src="item_img_list[toBasicItem(item)[1]]" style="width : 20px;" :class="{'item-have' : selected_items.includes(toBasicItem(item)[1])}"/>
                      </div>
                    </div>
                  </div>
                  <div v-for="sub_item in champion_list[dic.name]['items']" :key="sub_item">
                      <img v-if="!dic.items.includes(sub_item)" @error="imgReplace(2)" :src="item_img_list[sub_item]" class="rounded-circle mr-1" style="width:24px;" :class="{'item-have' : selected_items.includes(sub_item)}"/>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="mb-2 font-weight-light text-body-2">핵심 딜러진 필요 조합 아이템</div>
            <div class="position-relative d-inline-block rounded-circle mx-1" v-for="tuple, name in row.items_basic_delaer_sum" :key="tuple+name" :order="12-tuple[1]">
              <img @error="imgReplace(2)" :src="item_img_list[tuple[0]]" class="rounded-circle d-block mb-1" style="width : 2.5rem;"/>
              <span color="primary" class="py-n1 position-absolute delete-item d-flex align-center justify-center delete-item" style="bottom:0; right:0; font-size:0.8rem;">{{tuple[1]}}</span>
            </div>
          </v-col>


          <v-col cols="12" md="1" class="d-flex align-center pr-0">
            <span class="font-weight-light">빌드업</span>
          </v-col>
          <v-col cols="12"  md="5" class="">
            <div v-for="(dic, i) in row.champions" :key="i" class="d-flex mr-7 text-center">
              <v-row v-if="champion_list[dic.name]['cost'] > 3" class="d-flex mb-2">
                <v-col cols="3" md="2" class="pr-0">
                  <img @error="imgReplace(1)" :src="champion_list[dic.name]['image']" style="width : 2.5rem;" class="rounded-circle" :class="'champion-cost-'+champion_list[dic.name]['cost']"/>
                  <div class="text-caption text-center">{{dic.name}} </div>
                </v-col>

                <v-col cols="9" md="9" class="d-flex align-center overflow-x-auto">
                  <div v-for="bChampion in dic.buildup" :key="'b'+bChampion" class="d-flex flex-column justify-center mr-2 align-center">
                    <img @error="imgReplace(1)" :src="champion_list[bChampion]['image']" style="width : 2.2rem;" class="rounded-circle" :class="'champion-cost-'+champion_list[bChampion]['cost']"/>
                    <div class="text-caption text-center text-white" style="width : max-content;">{{bChampion}}</div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <!-- <v-col cols="6">
            <div class="d-inline-flex justify-start"  v-for="(test, i) in dic.items" :key="'dic'+i+test" > 
              <v-img :aspect-ratio="1" :width="48" :src="item_img_list[test]" class="mr-3"/>
                <div v-for="item in toBasicItem(test)" :key="item" class="text-white d-inline mr-2">
                  <img :src="item_img_list[item]" style="width : 20px;"/>
                </div>
            </div>
          </v-col> -->
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>


    <!-- 아래는 마이덱 -->
    <div class="position-fixed overflow-x-auto" style="bottom : 0; width:100%; background-color : #121212; z-index:999; max-width: 1056px;" v-if="mydeck['name']">
      <v-expansion-panel class="px-0">
        <v-expansion-panel-title class="text-left basic-border" expand-icon="m" collapse-icon="m">
          <v-btn @click="deleteMydeck" icon="mdi-minus" color="#121212" size="x-small" border="false" style="background-color:default; z-index:3; top:0; bottom:0; right:12px;" position="absolute" class="my-auto">
          </v-btn>
            선택한 덱 : {{mydeck.name}} 
        </v-expansion-panel-title>
        <v-expansion-panel-text style="max-height:400px;" class="overflow-y-auto">
          <v-row class="mt-2">
            <v-col cols="12" class="">
                <v-row>
                  <v-col cols="3" md="" v-for="(dic, i) in mydeck.champions" :key="i" class="text-center">
                    <img @error="imgReplace(1)" :src="champion_list[dic.name]['image']" style="width : 48px; height : 48px;" class="rounded-circle" :class="'champion-cost-'+champion_list[dic.name]['cost']"/>
                    <div class="text-caption text-center mx-auto" :class="{'core-champion' : dic.items}" style="width : max-content;">{{dic.name}} </div>
                  </v-col>
                </v-row>
            </v-col>
            <!-- <v-col cols="2" md="3" class="d-flex align-center basic-border-bottom">
              <span>점수</span>
            </v-col>
            <v-col cols="10" md="9" class="basic-border-bottom font-weight-thin">
              딜러 3신기 중 {{mydeck.items_basic_dealer_cnt}}개를 바로 만들 수 있어요 <br/>
              items_sub_dealer_cnt : {{row.items_sub_basic_dealer_cnt}} <br/>
              {{row.items_basic_total_cnt}}개의 완성 템을 지금 바로 사용할 수 있어요<br/>
              items_sub_basic_total_cnt : {{row.items_sub_basic_total_cnt}} <br/>
              3신기를 만들기 위한 {{mydeck.items_basic_sum_dealer_cnt}}개의 아이템을 보유하고 있어요 <br/>
              조합 아이템 총 {{mydeck.items_basic_sum_total_cnt}}개가 겹쳐요 <br/>
              deck_total_count : {{row.deck_total_count}}
            </v-col> -->


            <v-col cols="12" class="font-weight-thin">
              {{mydeck.name}}덱은 {{meta_desc[mydeck.name]}}
            <!-- 설명이 여기 들어갑니다. -->
            <!-- 딜러 3신기 중 {{row.items_basic_dealer_cnt}}개를 바로 만들 수 있어요 <br/>
            items_sub_dealer_cnt : {{row.items_sub_basic_dealer_cnt}} <br/>
            {{row.items_basic_total_cnt}}개의 완성 템을 지금 바로 사용할 수 있어요<br/>
            items_sub_basic_total_cnt : {{row.items_sub_basic_total_cnt}} <br/>
            3신기를 만들기 위한 {{row.items_basic_sum_dealer_cnt}}개의 아이템을 보유하고 있어요 <br/>
            조합 아이템 총 {{row.items_basic_sum_total_cnt}}개가 겹쳐요 <br/>
            deck_total_count : {{row.deck_total_count}} -->
          </v-col>

          <v-col cols="12" md="1" class="d-flex align-center pr-0">
            <span class="font-weight-light">아이템</span>
          </v-col>
          <v-col cols="12"  md="5" class="">
            <div v-for="(dic, i) in mydeck.champions" :key="i" class="d-flex mr-7 text-center">
              <v-row v-if="dic.items" class="d-flex mb-2">
                <v-col cols="3" md="2" class="pr-0">
                  <img @error="imgReplace(1)" :src="champion_list[dic.name]['image']" style="width : 2.5rem;" class="rounded-circle" :class="'champion-cost-'+champion_list[dic.name]['cost']"/>
                  <div class="text-caption text-center">{{dic.name}}</div>
                  <div v-if="dic.type == 'D'" class="text-caption text-center text-red-lighten-2 ">핵심 딜러</div>
                  <div v-else-if="dic.type == 'SD'" class="text-caption text-center text-orange-lighten-1">서브 딜러</div>
                  <div v-else-if="dic.type == 'U'" class="text-caption text-center text-green-lighten-1">유틸</div>
                  <div v-else-if="dic.type == 'T'" class="text-caption text-center text-blue-lighten-1">탱커</div>
                </v-col>
                <v-col cols="9" md="10" class="d-flex overflow-x-auto">
                  <div v-for="(item, i) in dic.items" :key="item" class="text-center mr-md-2" :class="{'mr-2' : i == dic.items.length -1}">
                    <img @error="imgReplace(2)" :src="item_img_list[item]" class="rounded-circle d-block mb-1 mx-1" style="width : 2.5rem;" :class="{'item-have-2' : selected_items.includes(item)}"/>
                    <div v-if="toBasicItem(item)[0] == toBasicItem(item)[1]" class="d-flex justify-center">
                      <div class="text-white d-inline mr-1 mr-md-2">
                        <img @error="imgReplace(2)" :src="item_img_list[toBasicItem(item)[0]]" style="width : 20px;" :class="{'item-have' : selected_items.includes(toBasicItem(item)[0])}"/>
                      </div>
                      <div class="text-white d-inline">
                        <img @error="imgReplace(2)" :src="item_img_list[toBasicItem(item)[1]]" style="width : 20px;" :class="{'item-have' : selected_items.filter(x => x==toBasicItem(item)[0]).length >= 2}"/>
                      </div>
                    </div>
                    <div v-else>
                      <div class="text-white d-inline mr-1 mr-md-2">
                        <img @error="imgReplace(2)" :src="item_img_list[toBasicItem(item)[0]]" style="width : 20px;" :class="{'item-have' : selected_items.includes(toBasicItem(item)[0])}"/>
                      </div>
                      <div class="text-white d-inline">
                        <img @error="imgReplace(2)" :src="item_img_list[toBasicItem(item)[1]]" style="width : 20px;" :class="{'item-have' : selected_items.includes(toBasicItem(item)[1])}"/>
                      </div>
                    </div>
                  </div>
                  <div v-for="sub_item in champion_list[dic.name]['items']" :key="sub_item">
                      <img v-if="!dic.items.includes(sub_item)" @error="imgReplace(2)" :src="item_img_list[sub_item]" class="rounded-circle" style="width:24px;" :class="{'item-have' : selected_items.includes(sub_item)}"/>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="mb-2 font-weight-light text-body-2">핵심 딜러진 필요 조합 아이템</div>
            <div class="position-relative d-inline-block rounded-circle mx-1" v-for="tuple, name in mydeck.items_basic_delaer_sum" :key="tuple+name" :order="12-tuple[1]">
              <img @error="imgReplace(2)" :src="item_img_list[tuple[0]]" class="rounded-circle d-block mb-1" style="width : 2.5rem;"/>
              <span color="primary" class="py-n1 position-absolute delete-item d-flex align-center justify-center delete-item" style="bottom:0; right:0; font-size:0.8rem;">{{tuple[1]}}</span>
            </div>
          </v-col>


          <v-col cols="12" md="1" class="d-flex align-center pr-0">
            <span class="font-weight-light">빌드업</span>
          </v-col>
          <v-col cols="12"  md="5" class="">
            <div v-for="(dic, i) in mydeck.champions" :key="i" class="d-flex mr-7 text-center">
              <v-row v-if="champion_list[dic.name]['cost'] > 3" class="d-flex mb-2">
                <v-col cols="3" md="2" class="pr-0">
                  <img @error="imgReplace(1)" :src="champion_list[dic.name]['image']" style="width : 2.5rem;" class="rounded-circle" :class="'champion-cost-'+champion_list[dic.name]['cost']"/>
                  <div class="text-caption text-center">{{dic.name}} </div>
                </v-col>

                <v-col cols="9" md="9" class="d-flex align-center overflow-x-auto">
                  <div v-for="bChampion in dic.buildup" :key="'b'+bChampion" class="d-flex flex-column justify-center mr-2 align-center">
                    <img @error="imgReplace(1)" :src="champion_list[bChampion]['image']" style="width : 2.2rem;" class="rounded-circle" :class="'champion-cost-'+champion_list[bChampion]['cost']"/>
                    <div class="text-caption text-center text-white" style="width : max-content;">{{bChampion}}</div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
            <!-- <v-col cols="6">
              <div class="d-inline-flex justify-start"  v-for="(test, i) in dic.items" :key="'dic'+i+test" > 
                <v-img :aspect-ratio="1" :width="48" :src="item_img_list[test]" class="mr-3"/>
                  <div v-for="item in toBasicItem(test)" :key="item" class="text-white d-inline mr-2">
                    <img :src="item_img_list[item]" style="width : 20px;"/>
                  </div>
              </div>
            </v-col> -->
          </v-row>
        </v-expansion-panel-text>
        
      </v-expansion-panel>
    </div>
  </v-expansion-panels>
  

</v-container>
</template>

<script>

export default {
  name: 'DeckList',
  props:[
    'deck',
    'status',
    'item_img_list',
    'item_list',
    'champion_list',
    'selected_items',
    'meta_desc'
  ],
  data: () => ({
    isActive : false,
    mydeck : {},
    inputChampions : "",
    deckFiltered : [],
    searchHelp : "",
    searchChampion : [],
    tab : null,
  }),
  methods : {
    toBasicItem : function(item){
      return this.item_list[item]
    },

    filterDeck : function(){
      this.deckFiltered =[];
      if (!this.inputChampions) {
        this.searchChampion = [];
        return
      }
      let length = Object.keys(this.deck).length

      let champions = this.inputChampions.split(',');
      champions.forEach(function(e, i){
        champions[i] = e.trim();
      })
      for (var i=0; i < length; i++) {
        let cnt = this.deck[i].champions.filter(element => champions.includes(element.name)).length
        if (cnt){
          let row = this.deck[i];
          row['cnt'] = cnt
          this.deckFiltered.push(this.deck[i])
        }
      }
      if (this.deckFiltered.length == 0 && this.inputChampions){
        this.searchHelp = "검색 결과가 없어 전체 결과로 표시합니다"
      } else {
        this.searchHelp =""
      }
      this.deckFiltered.sort((a,b) => (a.cnt > b.cnt ? -1 : 1));
      this.searchChampion = champions;
    },

    inputKeyUp : function(){
      if(!this.inputChampions){
        this.searchHelp="";
        this.filterDeck();
        return;
      }
      this.searchHelp = "엔터 버튼을 눌러주세요";
    },
    imgReplace : function(type){
      this.$emit('imgReplace', type)
    },
    pick : function(deck){
      this.mydeck = deck;
      // console.log(this.mydeck)
    },
    deleteMydeck : function(){
      this.mydeck = {};
    },
    onExpansionPanelClick(event) {
      if(event.currentTarget.classList.contains('v-expansion-panel--active')) {
        setTimeout(
          event.currentTarget.scrollIntoView({behavior: "smooth", block: "start"})
        ,1000)
      } 
    },
  },

}
</script>

<style scoped>
.v-expansion-panel {
  background-color : #121212;
  color : #ffffff;
}
.champion-cost-1{
  border: solid 2px #363636;
}
.champion-cost-2{
  border: solid 2px #10b642;  
}
.champion-cost-3{
  border: solid 2px #319eff;  
}
.champion-cost-4{
  border: solid 2px #ef38ff;  
}
.champion-cost-5{
  border: solid 2px #ffb93b;  
}
.champion-cost-8{
  border: solid 2px #f98d5f;  
}
.champion-cost-10{
  border: solid 2px #ed6767;  
}
.item-have{
  border : solid 1px #66ff94
}
.item-have-2{
  border : solid 2px #66ff94
}
.core-champion{
  color : #ff8a8a;
}
.search-champion{
  color : #e9ff23
}
.basic-border{
  border : solid 1px #363636;
}
.basic-border-bottom{
  border-bottom : solid 1px #363636;
}

.v-expansion-panel-title{
  padding-right : 16px;
  padding-left : 16px;
}

.v-expansion-panel--active {
  scroll-margin-top : 64px;
}
</style>
